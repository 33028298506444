import React, { useState } from 'react';
import { MdEmail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { SiGooglemaps } from "react-icons/si";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import 'swiper/css';
import { EMAIL, ADDRESS, PHONE_NUMBER, WPP_NUMBER, MAPS, WPP_LINK } from '../../constants';

function InfoCarousel() {
  const infoList = [
    { label: 'Email', content: EMAIL, href: 'mailto:', icon: <MdEmail /> },
    { label: 'Telefone', content: PHONE_NUMBER, icon: <BsTelephoneFill /> },
    { label: 'WhatsApp', content: WPP_NUMBER, href: `https://wa.me/${WPP_LINK}`, icon: <IoLogoWhatsapp /> },
    { label: 'Endereço', content: ADDRESS, href: MAPS, icon: <SiGooglemaps /> },
  ];

  return (
    <section className="text-center bg-sec w-screen flex items-center md:justify-between h-[30px] mt-[4px]">
      <Swiper
        modules={[Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        speed={500}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true, // Permite que a autoplay continue após interação do usuário
        }}
      >
        {
          infoList && infoList.map((info, index) => (
            <SwiperSlide key={index}>
              <div
                key={index}
                className={'flex items-center justify-center gap-[10px] md:flex text-pri md:w-full'}
              >
                <span className="text-lg font-bold">{info.icon}</span>
                <a className="truncate" target={`${info.id == 3 && '_blank'}`} href={info.href}>{info.content}</a>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </section>
  )
}

export default InfoCarousel;
