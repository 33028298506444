import React, { useEffect, useState } from 'react';
import InfoCarousel from './infoHeader';
import { PADDING } from '../../constants';
import importAll from '../../functions';
import LongMenu from './menu';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showCarousel, setShowCarousel] = useState(true);
  const [headerHeight, setHeaderHeight] = useState('100px');
  const [logoWidth, setLogoWidth] = useState('90px');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowCarousel(false);
        setHeaderHeight('40px');
        setLogoWidth('30px');
      } else {
        setShowCarousel(true);
        setHeaderHeight('100px'); // ou qualquer altura desejada quando estiver no topo
        setLogoWidth('90px'); // ou qualquer altura desejada quando estiver no topo
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const logo = importAll(require.context('../../imagens/logo', false, /\.(png|jpe?g|svg|jpg)$/));

  return (
    <header className={`bg-gray-100 h-fit fixed top-0 left-0 w-full transition duration-300 z-[300]`}>
      {showCarousel && <InfoCarousel />}
      <div className={`${PADDING} relative flex items-center justify-between p-100 py-[4px] bg-pri shadow-md`} style={{ height: headerHeight, transition: 'height 0.3s ease' }}>
        <LongMenu onClick={() => setMenuOpen(!menuOpen)} className="text-2xl" />
        <div className='absolute left-1/2 transform -translate-x-1/2 flex flex-col text-center'>
          <img className={`w-[70px] sm:w-[85px] sm:my-[5px]`} style={{ width: logoWidth }} src={logo[0].img} alt="Logo" />
        </div>
        <div></div>
      </div>
    </header>
  );
}

export default Header