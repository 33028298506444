import React from 'react';
import { FaFacebook, FaTiktok, FaInstagram } from "react-icons/fa";

const Footer = () => {

  const redes = [
    { title: 'Facebook', icon: <FaFacebook className='md:text-3xl'/>, href: 'https://www.facebook.com/share/puXo46Lq6pMmoTXt/' },
    { title: 'TikTok', icon: <FaTiktok className='md:text-3xl'/>, href: 'https://www.tiktok.com/@assel.natural?_t=8qeUfAmM72U&_r=1'},
    { title: 'Instagram', icon: <FaInstagram className='md:text-3xl'/>, href: 'https://www.instagram.com/asselnatural?igsh=bTMzajVkbXhmY2t1' },
    // { title: 'Mail', icon: <IoMdMail className='md:text-3xl'/>, href: '' },
  ]
  return (
    <footer>
      <div className='flex justify-center gap-[30px] text-center items-center bg-pri h-[100px]'>

        {redes.map((rede, index) => {
          return (
            <div key={index} className='bg-sec rounded-full h-[20px] w-[20px] flex justify-center items-center md:h-[40px] md:w-[40px]'>
              <a target="_blank" href={rede.href} title={rede.title}>{rede.icon}</a>
            </div>
          )
        })}
      </div>
      <div className='bg-[#253022] text-center text-sec py-[5px] text-sm'>
        Copyright © {new Date().getFullYear()}; Desenvolvido por: <b>HAMADA IT SOLUTIONS</b>.
      </div>
    </footer>
  )
}

export default Footer;