const PHONE_NUMBER = '(41) 9 9788-8709';
const WPP_NUMBER = '(41) 9 9788-8709';
const WPP_LINK = '55' + WPP_NUMBER.replace(/[\s()-]/g, '');
const ADDRESS = 'R. Des. Westphalen, 290 LOJA D7 - Centro, Curitiba - PR';
const EMAIL = 'asselnatural@gmail.com';
const MAPS = 'https://maps.app.goo.gl/HWfc8KpK88BayWVd8';
const INPUT_PROPS = {
  sx: {
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #87441f',
    },
    '&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '&:after': {
      borderBottom: '2px solid #87441f',
    },
  },
};
const INPUT_PROPS_LABEL = {
  sx: {
    color: '#004a35',
    '&.Mui-focused': {
      color: '#004a35',
      fontWeight: 700
    },
  },
};
const SX = {
  '& .MuiFilledInput-root': {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&.Mui-focused': {
      backgroundColor: 'white',
    }
  }, '& > :not(style)': { m: 1, width: '25ch' }
}
const PADDING = 'p-100 sm:p-s100 md:p-m100'


export { PHONE_NUMBER, WPP_NUMBER, ADDRESS, EMAIL, MAPS, WPP_LINK, INPUT_PROPS, INPUT_PROPS_LABEL, SX, PADDING };