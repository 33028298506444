import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PADDING } from '../../constants';
import Categories from '../../components/categories';

export default function AccordionUsage() {
  return (
    <div className={PADDING}>
      <Categories text={'sobre nós'} />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <p className='font-semibold'>Bem-vindo à Assel - Sua Fonte de Produtos Naturais Desde 1991.</p>
        </AccordionSummary>
        <AccordionDetails>
          A Assel vem oferecendo produtos naturais de alta qualidade para promover saúde e bem-estar. Fundada com o compromisso de conectar as pessoas à natureza.<br /><br />
          
          Nossa loja é administrada com carinho por Nielce  e Gilmar, que desde o início têm dedicado suas vidas para trazer até você os melhores produtos naturais do mercado.<br /><br />

          Com mais de três décadas de experiência, Nielce  e Gilmar trabalham diariamente para garantir que cada item escolhido em nossa loja seja uma solução saudável, sustentável e eficaz. Aqui, você encontrará uma grande variedade de produtos, como suplementos, alimentos orgânicos e itens de cuidados pessoais, sempre com um toque de atenção e cuidado de quem realmente se importa com sua saúde.<br /><br />

          Nossa missão é ajudar você a viver de maneira mais natural e equilibrada. Venha nos conhecer e faça parte da nossa jornada por um estilo de vida saudável!

        </AccordionDetails>
      </Accordion>
    </div>
  );
}