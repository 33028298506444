import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AiOutlineMenu } from 'react-icons/ai';

const options = [
  { descr: 'Início', href: ''},
  { descr: 'Acessórios e outros', href: '#acessorios' },
  { descr: 'Alimentos e bebidas', href: '#alimentos' },
  { descr: 'Cósmeticos e produtos naturais ', href: '#cosmeticos' },
  { descr: 'Ervas, chás e produtos de abelhas', href: '#ervas' },
  { descr: 'Sobre Nós', href: '#sobre' },
  { descr: 'Entre em contato', href: '#contato' },
];

const ITEM_HEIGHT = 48;

export default function LongMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToAnchor = (href) => {
    if (href === '') { // Verifica se o href está vazio
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Adiciona suavidade à rolagem
      });
      return; // Sai da função
    }
  
    const element = document.querySelector(href);
    if (element) {
      const offset = 60; // Distância do offset
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - offset; // Subtrai o offset
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth', // Adiciona suavidade à rolagem
      });
    }
  };

  const handleMenuItemClick = (event, href) => {
    handleClose(); // Fecha o menu
    scrollToAnchor(href); // Chama a função de rolagem
  };

  React.useEffect(() => {
    document.documentElement.style.scrollBehavior = 'smooth';
    return () => {
      document.documentElement.style.scrollBehavior = 'auto'; // opcional: redefina ao desmontar
    };
  }, []);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AiOutlineMenu className='text-sec' />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '35ch',
            },
          },
        }}
      >
        {options.map((option, key) => (
          <MenuItem key={key} onClick={(e) => handleMenuItemClick(e, option.href)}>
            <a href={option.href} onClick={(e) => e.preventDefault()}>{option.descr}</a>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
