import React, { useEffect, useState } from 'react';
import Carrosel from '../../components/carrosel';
import Categories from '../../components/categories';
import { PADDING, WPP_LINK } from '../../constants';
import importAll from '../../functions';

const Products = () => {
  const [slides, setSlides] = useState(2);

  const height = 'h-[150px]';
  const space = 10;
  const autoplay = true;
  const interaction = false;
  const widthItem =  'md:w-[135px]';

  useEffect(() => {
    const updateSlides = () => {
      if (window.innerWidth >= 1050) {
        setSlides(8);
      } else if (window.innerWidth >= 850) {
        setSlides(5);
      } else if (window.innerWidth >= 550) {
        setSlides(4);
      } else if (window.innerWidth >= 450) {
        setSlides(3);
      } else {
        setSlides(2);
      }
    }

    updateSlides();

    window.addEventListener('resize', updateSlides);

    return () => {
      window.removeEventListener('resize', updateSlides);
    };
  }, []);

  // Importar imagens das categorias
  const alimentosEbebidasImgs = importAll(require.context('../../imagens/Alimentos e Bebidas', false, /\.(png|jpe?g|svg)$/));
  const ervasChasProdutosAbelhaImgs = importAll(require.context('../../imagens/Ervas, Chás e Produtos de Abelha', false, /\.(png|jpe?g|svg)$/));
  const cosmeticosProdutosNaturaisImgs = importAll(require.context('../../imagens/Cosméticos e Produtos Naturais', false, /\.(png|jpe?g|svg)$/));
  const acessoriosOutrosImgs = importAll(require.context('../../imagens/Acessórios e Outros', false, /\.(png|jpe?g|svg)$/));

  // console.log(acessoriosOutrosImgs) 

  // Objeto de configuração com as imagens organizadas por categoria
  const configProdutos = {
    alimentosEbebidas: {
      name: 'Alimentos e Bebidas',
      imgs: alimentosEbebidasImgs,
      id: 'alimentos'
    },
    ervasChasProdutosAbelha: {
      name: 'Ervas, Chás e Produtos de Abelha',
      imgs: ervasChasProdutosAbelhaImgs,
      id: 'ervas'
    },
    cosmeticosProdutosNaturais: {
      name: 'Cosméticos e Produtos Naturais',
      imgs: cosmeticosProdutosNaturaisImgs,
      id: 'cosmeticos'

    },
    acessoriosOutros: {
      name: 'Acessórios e Outros',
      imgs: acessoriosOutrosImgs,
      id: 'acessorios'
    },
  };

  return (
    <article className={`${PADDING} flex flex-col gap-[50px]`}>
      {Object.keys(configProdutos).map((key) => {
        const { name, imgs, id } = configProdutos[key];
        return (
          <section key={key} className='flex flex-col' id={id}>
            <Categories text={name} />
            <Carrosel config={{imgs, height, space, slides, autoplay, interaction, widthItem }} />
            <a href={`https://wa.me/${WPP_LINK}?text=Olá,%20gostaria%20de%20saber%20quais%20outros%20produtos%20da%20categoria%20${name}%20vocês%20trabalham`} target='_blank' className='self-center bg-[#253022] text-[#d5e6b1] hover:bg-[#334d2a] font-roboto px-[4px] py-[2px] rounded'>Saiba mais</a>
          </section>
        )
      })}
    </article>
  )
}

export default Products