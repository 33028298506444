import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'swiper/css';
import Carrosel from '../../components/carrosel';
import Products from './products';
import About from './about';
import FormEmail from './formEmail';
import importAll from '../../functions';
import Footer from './footer';

const Main = () => {
  const alimentosEbebidasImg = importAll(require.context('../../imagens/capa', false, /\.(png|jpe?g|svg)$/), false);

  const configImgs = {
    imgs: alimentosEbebidasImg,
    height: 'h-[350px]',
    space: 0,
    slides: 1,
    interaction: false,
    autoplay: true,
    cover: true
  }

  return (
    <section className='flex flex-col gap-[30px] pt-[80px]'>
      <Carrosel config={configImgs} />
      <Products />
      <About />
      <div>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        <FormEmail />
        <ToastContainer />
        <Footer />
      </div>
    </section>
  )
}

export default Main;