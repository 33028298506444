import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormEmail = () => {
  const form = useRef();

  const notify = () => {
    toast.success('E-mail enviado com sucesso!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_03wa2e4', 'template_22sqi9j', form.current, {
        publicKey: '-0G3NYOObfaqynLzf',
      })
      .then(
        () => {
          notify();
          e.target.reset();
        },
        (error) => {
          alert(error.text)
        },
      );
  };

  return (
    <form ref={form} id='contact-form' onSubmit={sendEmail} className='bg-sec p-100 py-[20px] w-full flex justify-center'>
      <div className='flex flex-col items-center gap-[5px] w-max'>
        <h2 className='text-pri font-bold text-xl'>ENTRE EM CONTATO VIA E-MAIL</h2>
        <input placeholder='Nome' required type='text' name="name" className='px-[5px] rounded' />
        <input placeholder='E-mail' required type='email' name="reply_to" className='px-[5px] rounded' />
        <input placeholder='Celular' required name="cel" className='px-[5px] rounded' />
        <label className='text-pri font-semibold'>Mensagem</label>
        <textarea required type='text' rows='5' cols='40' className='rounded border-0 outline-none px-[5px]' name="message" />
        <div className='self-end'>
          <Stack direction="row" spacing={2}>
            <Button type='submit' value='send' variant="contained" endIcon={<SendIcon />}
              sx={{ backgroundColor: '#253022', color: '#d5e6b1', '&:hover': { backgroundColor: '#334d2a' } }}
            >
              Enviar
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
};

export default FormEmail;