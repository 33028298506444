import React from 'react';

const Categories = ({ text }) => {
  return (
    <div className="relative flex items-center justify-center w-full mb-[30px]">
      <hr className="border-pri border-1 w-full mx-4 md:w-1/2"/>
      <span className={`absolute px-4 text-pri font-semibold ${text.length < 20 ? 'text-xl' : 'text-sm'} md:text-xl bg-white`}>{text.toUpperCase()}</span>
    </div>
  )
}

export default Categories